.bottomfooter {
  background-color: #172755;
  /* margin-top: 5rem; */
}

.footer-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 0rem 2rem 0rem;
}

.footerlogo {
  width: 130px;
  height: 2.4rem;
}

.footer-links {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #8794ba;
  margin: 0rem 1rem;
}

.footer-s_links {
  color: #8794ba;
  font-size: 28px;
  margin: 0rem 1rem;
}

.pricing_section {
  padding: 4rem 0rem 0rem 0rem;
}

.pricing-background {
  height: 900px;
  max-height: 1000px;
  /* max-width: 1600px; */
  width: auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  vertical-align: middle;
}

.pricingright-illiustration {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -3;
  width: auto;
  top: 0%;
}

.space-top {
  padding-top: 6rem;
}

.cards-section {
  padding-top: 4rem;
}

.pricing_heading {
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  text-transform: capitalize;
}

.pricing_subheading {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #8794ba;
  margin: 0 auto;
  width: 80%;
}

.pricing_note {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #8794ba;
  margin: 0 auto;
  width: 80%;
  margin-top: 20px;
}

.pricing-cards {
  background: #0f1f4b;
  box-shadow: 0px 18px 50px -15px rgba(25, 42, 89, 0.2);
  border-radius: 30px;
  width: auto;
  height: 440px;
}

.selected-pricing-cards .card-body,
.pricing-cards .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.pricing-cards .card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}

.selected-pricing-cards .card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #172755;
}

.pricing-cards .sub-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #8794ba;
  line-height: 150%;
  margin-bottom: 0;
}

.selected-pricing-cards .sub-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #8794ba;
  line-height: 150%;
  margin-bottom: 0;
}

.pricing-cards hr {
  background: #fff;
  width: 80%;
  margin: 0 auto;
  height: 2px;
}

.selected-pricing-cards hr {
  background: #8794ba;
  width: 80%;
  margin: 0 auto;
  height: 2px;
}

.pricing-cards .prices {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 35px;
  color: #ffffff;
  text-align: center;
}

.selected-pricing-cards .prices {
  color: #172755;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 35px;
}

.pricing-cards .btn-order {
  padding: 16px 60px;
  border: 1px solid #2a407c;
  box-sizing: border-box;
  border-radius: 68px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.selected-pricing-cards .btn-order {
  padding: 14px 60px;
  background: #ffbe55;
  border: 1px solid #ffbe55;
  box-sizing: border-box;
  border-radius: 68px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.selected-pricing-cards {
  background: #fff;
  box-shadow: 0px 18px 50px -15px rgba(25, 42, 89, 0.2);
  border-radius: 30px;
  width: auto;
  height: 440px;
}

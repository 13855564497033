.Datapoints-section {
  margin: 5rem 0rem 0rem 0rem;
  background-color: #fff !important;
}

.imp-points {
  padding: 1.5rem 0rem;
}

.data_heading {
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  color: #172755;
  text-transform: capitalize;
}

.data_subheading {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #8794ba;
  margin: 0 auto;
  width: 90%;
}

.data_Points_Img {
  width: 100%;
  height: 400px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 20px;
  background-color: #fff;
}

.Points-Heading {
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  color: #172755;
  text-transform: capitalize;
  padding-bottom: 10px;
}

.Points-lists {
  font-size: 18px;
  font-weight: normal;
  line-height: 150%;
  font-style: normal;
  color: #8794ba;
  margin: 0 auto;
  width: 90%;
}

.listed-points ul {
  padding-left: 0;
}

.Image-content-sec {
  padding: 40px;
}

.spacing-top {
  padding-top: 4rem;
}

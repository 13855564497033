/* Medium devices (mobile and down) */
@media (min-width: 320px) {
  /* .three-points {
    display: none;
  } */

  .navbar-brand {
    font-size: 14px;
  }

  .links {
    display: none;
  }
  .footer-s_links {
    font-size: 20px;
  }

  .footerlogo {
    height: 20px;
    width: 50px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .links {
    display: unset;
  }
  .footerlogo {
    height: 20px;
    width: 50px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .footerlogo {
    height: 2.5rem;
    width: 100%;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}


@media only screen and (max-width: 600px) {
  .mobiletabs{
    margin-top: 0 !important;
  }
  .Datapoints-section{
    margin-top: -25px !important;
  }
}


/* Medium devices (mobile and down) */
@media (min-width: 320px) {
  /* .dataUsable_section {
    display: none;
  } */

  .dataUsable_heading {
    font-size: 28px;
    width: 100%;
  }

  .tabs,
  .active-tabs {
    padding: 11px 7px;
    font-size: 12px;
    width: 98%;
  }

  .mobiletabs {
    flex-grow: 1;
    padding: 30px 5px;
    margin-top: 3rem;
  }

  .content {
    height: 380px;
    padding: 5px;
  }

  .content-heading {
    padding: 10px;
    font-size: 20px;
  }

  .tab-sec-img {
    height: 280px;
  }

  .dataUsable_subheading {
    width: 100%;
  }

  .usable-tabs {
    height: 60px;
    width: 240px;
  }
  .scrolls {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 80px;
    white-space: nowrap;
  }
}

@media (min-width: 375px) {
  .tabs,
  .active-tabs {
    padding: 14px;
    font-size: 14px;
  }
  .usable-tabs {
    height: 60px;
    width: 240px;
  }
  .scrolls {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 80px;
    white-space: nowrap;
  }
}

@media (min-width: 414px) {
  .tabs,
  .active-tabs {
    padding: 10px 20px;
    font-size: 14px;
    width: 90%;
  }
  .scrolls {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 80px;
    white-space: nowrap;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* .dataUsable_section {
    display: unset;
  } */

  .marg-7 {
    margin-top: 1rem;
  }

  .content {
    width: auto;
    height: 600px;
  }

  .tab-sec-img {
    width: 100%;
    height: 400px;
  }
  .active-tabs {
    background-color: #ffbe55;
    color: #fff;
    border-color: #ffbe55;
    width: 90%;
    margin: 0 auto;
    padding: 12px 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  .tabs {
    background-color: #c4c4c4;
    color: #fff;
    border-color: #c4c4c4;
    width: 90%;
    margin: 0 auto;
    padding: 12px 2px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  .active-tabs,
  .tabs {
    padding: 12px 32px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .tab-sec-img {
    width: 100%;
    height: 450px;
    object-fit: contain;
    object-fit: contain;
  }

  .active-tabs,
  .tabs {
    padding: 12px 24px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .dataUsable_heading {
    font-size: 40px;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}

@media (min-width: 1920px) {
}

/* big */
@media (min-width: 2560px) {
}

.three-points {
  padding: 1rem 0rem 1rem 0rem;
}

.three-points-img {
  width: 140px;
  height: 120px;
}

.title-text {
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  text-transform: capitalize;
  color: #172755;
  text-align: center;
  padding: 1rem 0rem;
}

.para-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
  color: #8794ba;
}

.content-container {
  text-align: center;
}

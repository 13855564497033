.dataUsable_section {
  padding: 2rem 0rem 4rem 0rem;
  background-color: #d4e5e629;
  margin-bottom: 3rem;
  position: relative;
}

.imp-points {
  padding: 1.5rem 0rem;
}

.dataUsable_heading {
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  color: #172755;
  text-transform: capitalize;
}

.dataUsable_subheading {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #8794ba;
  margin: 0 auto;
  width: 60%;
}

.block-tabs {
  padding-top: 2rem;
}

.usable-tabs {
  height: 60px;
  width: 240px;
}

.active-tabs {
  background-color: #ffbe55;
  color: #fff;
  border-color: #ffbe55;
  width: 80%;
  margin: 0 auto;
  padding: 12px 25px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
}

.tabs {
  background-color: #c4c4c4;
  color: #fff;
  border-color: #c4c4c4;
  width: 80%;
  margin: 0 auto;
  padding: 12px 25px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
}

.content-tabs {
  flex-grow: 1;
  padding: 30px 30px;
}

.content {
  width: auto;
  height: 630px;
  background-color: #172755;
  border-radius: 20px;
  padding: 2.5rem 8rem;
  display: none;
}

.active-content {
  display: block;
}

.content-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 160%;
  color: #ffffff;
  text-align: center;
  padding-bottom: 1rem;
}

.tab-sec-img {
  width: 100%;
  height: 500px;
  border-radius: 10px;
  padding-bottom: 1rem;
  object-fit: contain;
}

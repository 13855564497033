/* Medium devices (mobile and down) */
@media (min-width: 320px) {
  .eventIntel_section {
    display: none;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .eventIntel_section {
    display: unset;
  }

  .marg-7 {
    margin-top: 4rem;
  }

  .event-points-icons {
    left: -180px;
  }

  .event-content {
    width: 35%;
    margin: 0 300px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .event-content {
    width: 55%;
    margin: 0 160px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .event-content {
    width: 45%;
    margin: 0 206px;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .event-points-icons {
    left: -200px;
  }

  .event-content {
    width: 40%;
    margin: 0 246px;
  }
}

@media (min-width: 1920px) {
  .event-points-icons {
    left: -200px;
  }
}

/* big */
@media (min-width: 2560px) {
}

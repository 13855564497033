.Nodata-section {
  padding: 0rem 0rem 4rem 0rem;
}

.background-graphics {
  width: auto;
  height: 470px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  vertical-align: middle;
  margin-bottom: 4rem;
  position: relative;
}

.Nodata_heading {
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  color: #172755;
  text-transform: capitalize;
  margin-bottom: 0;
}

.Nodata_subheading {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #8794ba;
  margin: 0 auto;
  width: 90%;
  padding-top: 2rem;
}

.col-pink {
  width: 280px;
  height: 200px;
  background: #ffbe55;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  display: flex;
  padding: 5px;
}

.col-blue {
  width: 280px;
  height: 200px;
  background: #172755;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  display: flex;
  padding: 5px;
}

.col-blue p,
.col-pink p {
  color: #fff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #fffbfb;
  margin-bottom: 0;
}

.contactus {
  padding: 5rem 0rem 0rem 0rem;
}

.contact_heading {
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  color: #172755;
  text-transform: capitalize;
}

.contact_subheading {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #8794ba;
  margin: 0 auto;
  width: 90%;
  padding-bottom: 3rem;
}

.imp-points {
  padding: 1rem 0rem;
}

.login-card {
  border-radius: 10px;
  border: 0px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.login-card .lefthand-side {
  background-color: #2a407c;
  border-radius: 10px;
  padding: 40px 40px;
}

.contact-form {
  padding: 1rem;
}

.lefthand-side h5 {
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  margin-bottom: 1rem;
}

.lefthand-side .content-text {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  width: 80%;
}

.contact-details {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  color: #fff;
}

.contact-icons {
  font-size: 28px;
  color: #ffbe55;
  margin-right: 10px;
}

.social-icons {
  font-size: 28px;
  color: #ffbe55;
  margin-right: 20px;
}

.cont-details {
  padding: 1rem 0rem;
}

.social-contact {
  padding-top: 3rem;
}

.radio-btns-text {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  color: #2a407c;
}

.contact-form label {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  color: #2a407c;
}

.form-btn {
  padding-top: 2rem;
  text-align: end;
}

.btn-sendMessage {
  padding: 10px 40px;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  background-color: #2a407c;
  color: #fff;
  border-radius: 10px;
}

.navigation {
  background-color: transparent;
  z-index: 10;
  margin-top: 10px;
}

.websitelogo {
  width: auto;
  height: 2.5rem;
  padding-right: 8px;
}

.logo_name {
  font-size: 1.9rem;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 400;
  color: #8794ba;
  margin: 0 10px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #ffbe55;
}

.navbar-light .navbar-brand {
  color: #fff;
  padding: 0;
}

.navbar-brand :hover {
  color: #ffbe55;
}

.btn-demo {
  border-radius: 25px;
  padding: 4px 45px;
  background-color: transparent;
  border-color: #8794ba;
  text-transform: uppercase;
  color: #8794ba;
  font-size: 16px;
  font-weight: 400;
}

.btn-demo:hover {
  background-color: #ffbe55;
  color: #fff;
  border-color: #ffbe55;
}

.banner-background {
  height: 700px;
  max-height: 1080px;
  /* max-width: 1600px; */
  width: auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  vertical-align: middle;
}

.Data_intelligence {
  width: auto;
  height: 500px;
  position: absolute;
  z-index: 3;
  right: 0px;
  top: 110px;
  box-shadow: 0px 18px 50px -15px rgb(23 39 85 / 48%);
}

.section-position {
  z-index: 10;
}

.left-content {
  padding: 120px 0px 0px 0px;
}

/* .intro-section {
  width: 550px;
  height: 60px;
} */

.intro-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 120%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  flex-grow: 0;
  margin: 15px 0px;
}

.intro-subheading {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #8794ba;
}

.btn-Know_more {
  padding: 10px 80px;
  background: #ffbe55;
  border-radius: 68px;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin: 30px 0px;
}

.topleft-illiustration {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
  width: "auto";
  max-width: "auto";
}

.topright-illiustration {
  position: absolute;
  right: 0px;
  bottom: 0;
  top: 0px;
  z-index: -3;
  width: 636px;
}

.dropdown-toggle::after{
  display: none; 
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #ffffff;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 320px) {
  .navigation {
    background-color: #172755;
    box-shadow: 0px 4px 4px #12214d;
    z-index: 15;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  .navbar-light .navbar-toggler {
    color: #fff;
    border-radius: #fff;
  }

  .navbar-light .navbar-toggler-icon {
    background-color: rgb(246 246 247 / 43%);
  }

  .topright-illiustration,
  .topleft-illiustration {
    display: none;
  }

  .left-content {
    padding-top: 80px;
    text-align: center;
  }

  .Data_intelligence {
    position: relative;
    height: 190px;
    top: 0;
  }

  .intro-section {
    text-align: center;
  }

  .intro-heading {
    font-size: 24px;
  }

  .intro-subheading {
    line-height: 130%;
    font-size: 14px;
  }

  .btn-Know_more {
    margin: 8px 0px;
    padding: 5px 70px;
    font-size: 14px;
  }

  .certainty-pad {
    padding: 0;
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .topright-illiustration,
  .topleft-illiustration {
    display: none;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .banner-background {
    height: 500px;
  }

  .left-content {
    padding-top: 30px;
    text-align: left;
  }

  .intro-section {
    width: 360px;
    text-align: left;
    padding-top: 50px;
  }

  .Data_intelligence {
    width: auto;
    height: 280px;
    position: absolute;
    z-index: 3;
    right: 0px;
    top: 150px;
    /* bottom: 75px; */
    box-shadow: 0px 18px 50px -15px rgb(23 39 85 / 48%);
  }

  .intro-heading {
    font-size: 26px;
    line-height: 100%;
  }

  .intro-subheading {
    font-size: 16px;
  }

  .btn-Know_more {
    margin: 20px 0px;
  }

  .topleft-illiustration {
    width: auto;
    height: 200px;
  }

  .topright-illiustration {
    width: auto;
    height: 450px;
    /* right: -100px; */
  }
  .topright-illiustration,
  .topleft-illiustration {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .navigation {
    box-shadow: none;
    background-color: transparent;
  }

  .topright-illiustration,
  .topleft-illiustration {
    display: unset;
  }
  .banner-background {
    height: 630px;
  }

  .topright-illiustration {
    width: auto;
    height: 550px;
  }

  .Data_intelligence {
    height: 400px;
  }
  .left-content {
    padding: 120px 0px 0px 0px;
  }
  .intro-section {
    width: 530px;
    text-align: left;
    padding-top: 50px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .intro-section {
    width: 530px;
    text-align: left;
    padding-top: 50px;
  }
  .left-content {
    padding: 140px 0px 0px 0px;
  }

  .banner-background {
    height: 660px;
  }
  .topleft-illiustration {
    width: auto;
    height: 370px;
  }
  .topright-illiustration {
    width: auto;
    height: 580px;
    /* right: -160px; */
  }

  .Data_intelligence {
    height: 450px;
    right: 0px;
  }
  .intro-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    flex-grow: 0;
    margin: 15px 0px;
  }

  .intro-subheading {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    color: #8794ba;
  }

  .btn-Know_more {
    padding: 14px 80px;
    background: #ffbe55;
    border-radius: 68px;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    margin: 30px 0px;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .left-content {
    padding: 150px 0px 0px 50px;
  }

  .banner-background {
    height: 750px;
  }

  .topright-illiustration {
    position: absolute;
    right: -130px;
    bottom: 0;
    top: 0px;
    z-index: -2;
    height: 650px;
  }
  .topleft-illiustration {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .Data_intelligence {
    height: 470px;
    top: 200px;
  }
}

/* //(1920x1080) Full HD Display */

@media (min-width: 1920px) {
  .banner-background {
    height: 850px;
  }
  .left-content {
    padding: 220px 0px 0px 50px;
  }

  .topright-illiustration {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0px;
    z-index: -2;
    height: 730px;
  }
  .topleft-illiustration {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .Data_intelligence {
    height: 570px;
    top: 200px;
  }
}

/* big */
@media (min-width: 2560px) {
  .topright-illiustration {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0px;
    z-index: -2;
    height: 950px;
  }
  .topleft-illiustration {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .Data_intelligence {
    height: 600px;
  }
  .banner-background {
    height: 850px;
  }
}

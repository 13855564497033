.eventIntel_section {
  margin: 6rem 0rem 0rem 0rem;
}

.event_heading {
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  color: #172755;
  text-transform: capitalize;
}

.imp-points {
  padding: 1.5rem 0rem;
}

.Bg-illiustration {
  width: auto;
  height: 388px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.event-points-icons {
  width: 100%;
  height: 150px;
  position: absolute;
  left: -160px;
  top: 85px;
}

.event-content {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 0 260px;
}

.first {
  padding-top: 70px;
  padding-left: 20px;
}

.second {
  padding-top: 50px;
  padding-left: 20px;
}

.third {
  padding-top: 50px;
  padding-left: 20px;
}

.fourth {
  padding-top: 80px;
  padding-left: 20px;
}

.event-content h5 {
  padding-bottom: 20px;
  font-style: normal;
  font-size: 22px;
  font-weight: 600;
  line-height: 130%;
  color: #212353;
}
.event-content .info {
  padding-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: #4b5d68;
}

.learn-more {
  padding: 0;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.005em;
  color: #212353;
}

.learn-more-arrow {
  color: #9c69e2;
  padding-left: 10px;
  font-size: 36px;
  padding-top: 5px;
}

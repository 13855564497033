/* Medium devices (mobile and down) */
@media (min-width: 320px) {
  .form-btn {
    text-align: center;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .marg-7 {
    margin-top: 6rem;
  }

  .login-card .lefthand-side {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .login-card .lefthand-side {
    display: unset;
  }

  .login-card {
    margin: 30px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}

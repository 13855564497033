@media (min-width: 320px) {
  .Datapoints-section {
    /* padding: 4rem 0rem 5rem 0rem; */
  }

  .data_Points_Img {
    height: 200px;
  }

  .data_heading {
    font-size: 28px;
  }

  .Points-Heading {
    font-size: 30px;
  }

  .Points-lists {
    font-size: 16px;
  }

  .spacing-top {
    padding-top: 2.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .pricing_section  {
    margin-top: -50px !important;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Datapoints-section {
    /* padding: 4rem 0rem 5rem 0rem; */
  }

  .data_Points_Img {
    width: 100%;
    height: 400px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-radius: 20px;
  }

  .data_heading {
    font-size: 40px;
    font-weight: 700;
    font-style: normal;
    color: #172755;
    text-transform: capitalize;
  }

  .data_subheading {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    color: #8794ba;
    margin: 0 auto;
    width: 90%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .navigation {
    background-color: transparent;
    box-shadow: none;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}

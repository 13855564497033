@media (min-width: 320px) {
  /* .Nodata-section {
    display: none;
  } */
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .col-pink,
  .col-blue {
    width: 200px;
    height: 160px;
  }

  .background-graphics {
    height: 420px;
    margin-top: 2rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* .Nodata-section {
    display: unset;
  } */

  ._points {
    padding: 0px 25px;
  }

  .col-pink {
    width: 220px;
    height: 160px;
    background: #ffbe55;
    border-radius: 10px;
    text-align: center;
  }
  .col-blue {
    width: 220px;
    height: 160px;
    background: #172755;
    border-radius: 10px;
    text-align: center;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .col-pink {
    width: 250px;
    height: 200px;
    background: #ffbe55;
    border-radius: 10px;
    text-align: center;
  }
  .col-blue {
    width: 250px;
    height: 200px;
    background: #172755;
    border-radius: 10px;
    text-align: center;
  }

  .background-graphics {
    margin-top: 2rem;
    height: 500px;
    width: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .col-pink {
    width: 280px;
    height: 240px;
    background: #ffbe55;
    border-radius: 10px;
    text-align: center;
  }
  .col-blue {
    width: 280px;
    height: 240px;
    background: #172755;
    border-radius: 10px;
    text-align: center;
  }
}

/* Medium devices (mobile and down) */
@media (min-width: 320px) {
  .certainty_heading {
    font-size: 28px;
  }
  .certainty_subheading {
    font-size: 16px;
  }

  .icons_subtext {
    width: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .certainty-pad {
    padding: 0;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .certainty_heading {
    font-size: 40px;
    font-weight: 700;
    font-style: normal;
    color: #172755;
    text-transform: capitalize;
  }

  .certainty_subheading {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    color: #8794ba;
    margin: 0 auto;
    width: 90%;
  }

  .certainty-pad {
    padding: 40px 120px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .certainty-pad {
    padding: 40px 120px;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .certainty-pad {
    padding: 40px 120px;
  }
}

/* Medium devices (mobile and down) */
@media (min-width: 320px) {
  .cards-section {
    display: none;
  }

  .pricingright-illiustration {
    display: none;
  }

  .pricing_heading {
    font-size: 28px;
  }
  .pricing-background {
    margin-top: 0rem;
  }
}

@media only screen and (max-width: 600px) {
  .pricing_note{
    margin-top: -30px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .cards-section {
    display: flex;
  }

  .pricing_heading {
    font-size: 40px;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    text-transform: capitalize;
  }

  .pricing_subheading {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    color: #8794ba;
    margin: 0 auto;
    width: 80%;
  }

  .pricing-cards .card-title,
  .selected-pricing-cards .card-title {
    font-size: 24px;
    line-height: 20px;
  }
  .pricing-cards .sub-text,
  .selected-pricing-cards .sub-text {
    font-size: 18px;
  }

  .pricing-cards .prices,
  .selected-pricing-cards .prices {
    font-size: 40px;
    line-height: 22px;
  }
  .pricing-cards .btn-order,
  .selected-pricing-cards .btn-order {
    width: 80%;
    padding: 14px 40px;
  }

  .pricingright-illiustration {
    display: none;
  }

  .mobile-section {
    display: none;
  }

  .mobile-pricing-cards {
    background-color: #fff;
    border-radius: 30px;
    width: auto;
    height: 440px;
  }

  .mobile-pricing-cards .mobile-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 200px;
  }

  .mobile-pricing-cards .card-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #172755;
  }

  .mobile-pricing-cards .sub-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #8794ba;
    line-height: 150%;
    margin-bottom: 0;
  }

  .mobile-pricing-cards hr {
    background: #8794ba;
    width: 80%;
    margin: 0 auto;
    height: 2px;
  }

  .mobile-pricing-cards .prices {
    color: #172755;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 35px;
  }

  .mobile-pricing-cards .mobilebtn-order {
    padding: 14px 60px;
    background: #ffbe55;
    border: 1px solid #ffbe55;
    box-sizing: border-box;
    border-radius: 68px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    margin: 0 auto;
    color: #fff;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .pricingright-illiustration {
    display: none;
  }
  .pricing-background {
    margin-top: 5rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .pricing-background {
    width: auto;
    height: 1000px;
    margin-top: 8rem;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .pricing-background {
    width: auto;
    height: 1000px;
  }

  .space-top {
    padding-top: 10rem;
  }
}

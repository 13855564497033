/* .certainty-section {
  margin: 6rem 0rem 0rem 0rem;
} */

.certainty-pad {
  padding: 40px 120px;
  margin: auto;
  width: 75%;
}

.certainty_heading {
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  color: #172755;
  text-transform: capitalize;
}

.certainty_subheading {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #8794ba;
  margin: 0 auto;
  width: 90%;
}

.imp-points {
  padding: 1.5rem 0rem;
}

.certainty_icons {
  width: 150px;
  height: 150px;
}

.icons_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  color: #172755;
  padding: 15px 0px 15px 0px;
  margin-bottom: 0;
}

.icons_subtext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: #8794ba;
  width: 55%;
  margin: 0 auto;
}

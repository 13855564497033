/* Medium devices (mobile and down) */
@media (min-width: 320px) {
  /* .three-points {
    display: none;
  } */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* .three-points {
    display: unset;
  } */

  .three-points-img {
    width: 120px;
    height: 100px;
  }
  .title-text {
    font-size: 22px;
  }

  .pad-20 {
    padding: 20px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
